<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-20 14:42:31
 * @LastEditors: ------
 * @LastEditTime: 2021-10-25 13:43:29
-->
<template>
  <div class="aboutus_container">
    <div class="aboutus_banner">
      <img src="@/assets/aboutUs/banner.png" alt="" />
    </div>
    <div class="sticky">
      <van-tabs
        title-active-color="#F58B1C"
        swipeable
        animated
        border
        sticky
        :ellipsis="false"
        color="#F58B1C"
        v-model="num.id"
        @change="change"
      >
        <van-tab v-for="(item, index) in tab" :title="item" :key="index">
          <div class="tab_item tab_one" v-show="num.id == 0">
            <div class="tab_title">
              {{ item }}
            </div>
            <div class="one_img">
              <img src="@/assets/aboutUs/map.png" alt="" />
            </div>
            <p class="tab_one_font">
              上海爱介护社区养老服务有限公司成立于2015
              年，始终致力于为社区居家长者提供方便，快捷，高质量，人性化的健康介护服务，并逐步成为国内居家养老行业独具特色的品牌和服务连锁机构。
            </p>
            <p class="tab_one_font">
              服务项目：主要服务项目有长护险服务，健康管家，生活照料，医院陪护，乐养服务，老年餐定制配送，钟点服务以及适老化改造等。
            </p>
            <div class="tab_one_font">
              公司拥有一支具有国际背景和先进养老管理理念的高效运营团队，已有员工三百多人，护理和专业技术人员占90%以上，护士以上技术职称人员有20多人，所有的护理人员均为执业资格护士或经过专业护理和服务培训的养老护理专员，服务人数达三千多人。
            </div>
            <div class="one_img">
              <img src="@/assets/aboutUs/about1.png" alt="" />
            </div>
            <p class="tab_one_font">
              企业使命：誓怀大仕恻隐之心，普助天下有需老人！
              让天下长者都能幸福和尊严的老去！
            </p>
            <p class="tab_one_font">
              价值观： 利他、精进、忠信。
            </p>
            <p class="tab_one_font">
              服务理念：社会放心、子女安心、长者舒心、服务贴心。
            </p>
            <p class="tab_one_font">
              服务宗旨：我们的全部努力都是为了长者和家属满意。
            </p>
          </div>
          <div class="tab_item tab_two" v-show="num.id == 1">
            <div class="tab_title">
              {{ item }}
            </div>
            <div class="two_box">
              <div class="twoLine"></div>
              <div class="circle2">
                <img src="@/assets/aboutUs/circle2.png" alt="" />
              </div>
              <div class="two_line">
                <div class="tree1">
                  <img src="@/assets/aboutUs/tree1.png" alt="" />
                </div>
                <div
                  class="line_item"
                  v-for="(item, index) in develop"
                  :key="index"
                >
                  <div class="item_top">
                    <div class="circle1">
                      <img src="@/assets/aboutUs/circle1.png" alt="" />
                    </div>
                    <img
                      class="lineImg"
                      src="@/assets/aboutUs/line.png"
                      alt=""
                    />
                  </div>
                  <p class="time">{{ item.historyTime.substr(0, 10) }}</p>
                  <p class="twoCon">
                    {{ item.historyContent }}
                  </p>
                </div>
                <div class="tree2">
                  <img src="@/assets/aboutUs/tree2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="tab_item tab_three" v-show="num.id == 2">
            <div class="tab_title">
              {{ item }}
            </div>
            <div class="three_box">
              <div
                class="box_img"
                v-for="(item, index) in employ"
                :key="index"
                @click="href(item.url)"
              >
                <div class="img_yuangong">
                  <img v-lazy="item.imageUrl" alt="" />
                </div>
                <div>
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
          <div class=" tab_four" v-show="num.id == 3">
            <div class="partner">
              <div class="partner_title">{{ item }}</div>
              <div class="partner_icon">
                <img src="@/assets/home/partner1.png" alt="" />
                <img src="@/assets/home/partner2.png" alt="" />
                <img src="@/assets/home/partner3.png" alt="" />
                <img src="@/assets/home/partner4.png" alt="" />
                <img src="@/assets/home/partner5.png" alt="" />
                <img src="@/assets/home/partner6.png" alt="" />
                <img src="@/assets/home/partner7.png" alt="" />
                <img src="@/assets/home/partner8.png" alt="" />
                <img src="@/assets/home/partner9.png" alt="" />
                <img src="@/assets/home/partner10.png" alt="" />
                <img src="@/assets/home/partner11.png" alt="" />
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getHistoryList, getShowList } from "@/utils/request";

export default {
  name: "aboutUs",

  data() {
    return {
      tab: ["公司简介", "发展历程", "员工风采", "合作伙伴"],
      num: {
        id: this.$store.state.jobNum, // 首次从vuex中获取
      },
      develop: [],
      employ: [],
    };
  },
  computed: {
    listData() {
      return this.$store.state.jobNum;
    },
  },
  created() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      getHistoryList().then((res) => {
        this.develop = res.data.result.records;
      });
      getShowList().then((res) => {
        this.employ = res.data.result.records;
        // console.log(res);
      });
    },
    change(e) {
      this.num.id = e;
      this.$store.commit("setjobNum", e); // 存放在状态管理
    },
    href(val) {
      window.open(val);
    },
  },
  watch: {
    listData(newVal) {
      // 监听vuex 数据变化 ， 如果改变就重新赋值
      this.num.id = newVal;
    },
  },
};
</script>

<style scoped lang="less">
.aboutus_container {
  .aboutus_banner {
    img {
      width: 100%;
    }
  }
  .tab_item {
    padding: 30px 14px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: var(--aboutColor);
    .tab_title {
      padding-bottom: 24px;
    }
    .one_img {
      padding: 10px 0;
      img {
        width: 348px;
        height: 182px;
      }
    }
    .tab_one_font {
      color: var(--color333);
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: left;
      padding-bottom: 10px;
      // text-indent: 2em;
    }
    .two_box {
      padding-left: 20px;
      margin-top: 40px;
      height: 100%;
      position: relative;
      .twoLine {
        border-left: 1px solid var(--aboutColor);
        position: absolute;
        height: 100%;
        left: 30px;
      }
      .circle2 {
        position: absolute;
        left: 8px;
        top: -18px;
        img {
          width: 46px;
          height: 46px;
        }
      }
      .two_line {
        padding-top: 80px;
        position: relative;
        .tree1 {
          position: absolute;
          top: -50px;
          right: -36px;
          img {
            width: 166px;
            height: 166px;
          }
        }
        .tree2 {
          position: absolute;
          bottom: -56px;
          right: 0;
          img {
            width: 174px;
            height: 236px;
          }
        }
        .line_item {
          text-align: left;
          padding-bottom: 24px;
          .item_top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .lineImg {
              width: 282px;
              height: 8px;
            }
            .circle1 img {
              width: 21px;
              height: 21px;
            }
          }
          .time,
          .twoCon {
            line-height: 1.8;
            padding-left: 46px;
          }
          .time {
            color: var(--aboutColor);
            font-size: 16px;
            font-weight: 500;
          }
          .twoCon {
            color: var(--color333);
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .tab_three {
    .three_box {
      color: var(--color333);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .box_img {
        text-align: left;
        width: 168px;
        padding-bottom: 26px;
        .img_yuangong img {
          height: 134px;
          width: 168px;
        }
      }
    }
  }
  .tab_four {
    text-align: center;
    .partner {
      background-color: var(--bilibiColor);
      padding: 20px 8px 50px 8px;
      margin-top: 20px;
      color: var(--white);
      .partner_title {
        padding: 20px 0 20px 0;
        font-size: 22px;
        font-weight: bold;
      }
      .partner_icon {
        display: flex;
        // justify-content: space-around;
        justify-content: flex-start;
        flex-wrap: wrap;
        img {
          margin: 10px 6px 0 5px;
          width: 168px;
          height: 72px;
        }
      }
    }
  }

  /deep/ .van-tab {
    font-size: 16px;
  }
  /deep/ .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0 0 1px 0;
    border-color: var(--color666);
  }
  /deep/ .van-sticky--fixed {
    top: 56px;
  }
}
</style>
